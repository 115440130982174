import { Dispatch, SetStateAction } from "react";

interface ISavedPaymentMethods {
  paymentMethods: any[];
  selectedPaymentMethodId: string;
  setSelectedPaymentMethodId: Dispatch<SetStateAction<string>>;
}

const SavedPaymentMethods = ({
  paymentMethods,
  selectedPaymentMethodId,
  setSelectedPaymentMethodId,
}: ISavedPaymentMethods) => {
  return (
    <div>
      <ul
        style={{
          listStyle: "none",
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        {/* TODO please style this one. Looks plain and old. Check the network tab and render image according to card types */}
        {paymentMethods.map((paymentMethod) => {
          const card = paymentMethod.card;

          return (
            <li
              style={{
                padding: 10,
                border: "1px solid black",
                borderRadius: 30,
                marginTop: 20,
              }}
              key={paymentMethod.id}
            >
              <input
                type="radio"
                name="paymentMethod"
                value={paymentMethod.id}
                checked={selectedPaymentMethodId === paymentMethod.id}
                onChange={() => setSelectedPaymentMethodId(paymentMethod.id)}
              />

              <label style={{ marginLeft: 10 }} htmlFor={card.id}>
                **** **** **** {card.last4}
                <span>
                  EXP: {paymentMethod.card.exp_month}/
                  {paymentMethod.card.exp_year}
                </span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SavedPaymentMethods;
