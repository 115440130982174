import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";
import style from "./signup.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
type stepDataType = {
  firstname: string;
  email: string;
  password: string;
  phone: string;
};

const initialStepData = {
  firstname: "",
  email: "",
  password: "",
  phone: "",
};
type searchDataType = {
  isSearched: boolean;
  keywordSearch: string;
  keywordState: string;
  categoriesState: string;
  country: string;
  audience: string;
  audienceMin: number;
  audienceMax: number;
  maxResults: number;
};
const SignUp = (props: {
  setPaymentData: Function;
  user: {
    islogged: boolean;
    email: string;
    token: string;
  };
  setUser: Function;
  searchParams: searchDataType;
}): JSX.Element => {
  let navigate = useNavigate();
  useEffect(() => {
    if (props.user.islogged) {
      navigate("/search");
    }
  }, []);

  const [signUpData, setSignUpData] = useState<stepDataType>(initialStepData);
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSignUpData({
      ...signUpData,

      [event.target.name]: event.target.value,
    });
  };
  const sendSignUpData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetch("https://people-backend.upgiant.com/auth/signup", {
      method: "post",

      headers: { "Content-Type": "application/json" },

      body: JSON.stringify({
        email: signUpData.email,
        password: signUpData.password,
        phoneNumber: signUpData.phone,
        firstName: signUpData.firstname,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.message == undefined) {
          props.setUser({
            islogged: true,
            email: signUpData.email,
            token: result.token,
          });
          localStorage.setItem(
            "pu_user",
            JSON.stringify({
              islogged: true,
              email: signUpData.email,
              token: result.token,
              time: new Date().valueOf(),
            })
          );
          fetch("https://people-backend.upgiant.com/auth/profile", {
            headers: { Authorization: result.token },
          })
            .then((res) => res.json())
            .then((result) => {
              props.setPaymentData({
                credits: result.credits,
                stripeCustomerId: result.stripeCustomerId,
              });
              localStorage.setItem(
                "pu_payment",
                JSON.stringify({
                  credits: result.credits,
                  stripeCustomerId: result.stripeCustomerId,
                })
              );
              navigate("/search");
            });
        } else {
          setErrorMessage(result.message);
        }
      });
  };
  return (
    <div className="pageWrapper">
      <main className="mainWrapper">
        <Header user={props.user} link={false} />
        <Box className={style.mainHeadingBox}>
          <Title type="h1">Win the outreach numbers game.</Title>
        </Box>
        <Box className={style.subHeadingBox}>
          <Title type="h2">Sign up for free</Title>
        </Box>
        <Box className={style.formBox}>
          <form onSubmit={sendSignUpData}>
            <Box className={style.inputBox}>
              <TextField
                onChange={handleChange}
                className={style.inputText}
                placeholder="First name"
                type="text"
                name="firstname"
                variant="outlined"
              />
            </Box>
            <Box className={style.inputBox}>
              <TextField
                onChange={handleChange}
                className={style.inputText}
                placeholder="Email"
                type="email"
                name="email"
                variant="outlined"
              />
            </Box>
            <Box className={style.inputBox}>
              <TextField
                onChange={handleChange}
                className={style.inputText}
                placeholder="Password"
                type="password"
                name="password"
                variant="outlined"
              />
            </Box>
            <Box className={style.inputBox}>
              <TextField
                onChange={handleChange}
                className={style.inputText}
                placeholder="Phone"
                type="text"
                name="phone"
                variant="outlined"
              />
            </Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                className={style.button}
              >
                Sign up
              </Button>
            </Box>
          </form>
        </Box>
        <Box className={style.errorFormBox}>{errorMessage}</Box>
        <Box className={style.afterFormBox}>
          Already registered? please,
          <Link to="/login">Log in</Link>
        </Box>
        <Box className={style.termsBox}>
          By signing up, you agree to our{" "}
          <Link to="/terms">Terms of Service</Link> and{" "}
          <Link to="/privacy">Privacy Policy</Link>
        </Box>
      </main>
    </div>
  );
};

export default SignUp;
