import { useState } from "react";
import "./common.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import Home from "./pages/Home/Home";
import Search from "./pages/Search/Search";
import ThankYou from "./pages/ThankYou/ThankYou";

import { Routes, Route } from "react-router-dom";
import SignUp from "./pages/SignUp/SignUp";
import Login from "./pages/Login/Login";
import InfoText from "./pages/InfoText/InfoText";
import OptOut from "./pages/OptOut/OptOut";
import PaymentPage from "./pages/PaymentPage/PaymentPage";
import Landing from "./pages/Landing/Landing";

const terms = {
  title: "Terms and Conditions",
  text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
};

const privacy = {
  title: "Privacy Policy",
  text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
};

function App() {
  const [user, setUser] = useState({ islogged: false, email: "", token: "" });
  const [paymentData, setPaymentData] = useState({
    credits: 50,
    stripeCustomerId: "",
  });
  const [query, setQuery] = useState({ id: "" });
  const [searchParams, setSearchParams] = useState({
    isSearched: false,
    keywordSearch: "",
    keywordState: "",
    categoriesState: "",
    country: "",
    audience: "",
    audienceMin: 0,
    audienceMax: 0,
    maxResults: 0,
  });
  return (
    <Routes>
      <Route element={<Home user={user} />} />
      <Route
        path="dashboard"
        element={
          <Dashboard
            user={user}
            setUser={setUser}
            setPaymentData={setPaymentData}
          />
        }
      />
      <Route
        path="search"
        element={
          <Search
            user={user}
            setPaymentData={setPaymentData}
            paymentData={paymentData}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setQuery={setQuery}
            setUser={setUser}
          />
        }
      />
      <Route
        path="sign-up"
        element={
          <SignUp
            setUser={setUser}
            searchParams={searchParams}
            user={user}
            setPaymentData={setPaymentData}
          />
        }
      />
      <Route
        path="login"
        element={
          <Login
            user={user}
            setUser={setUser}
            setPaymentData={setPaymentData}
          />
        }
      />
      <Route
        path="thank-you"
        element={
          <ThankYou
            user={user}
            setUser={setUser}
            setPaymentData={setPaymentData}
          />
        }
      />
      <Route
        path="thank-you-page"
        element={
          <ThankYou
            user={user}
            setUser={setUser}
            setPaymentData={setPaymentData}
          />
        }
      />
      <Route
        path="payment"
        element={
          <PaymentPage
            user={user}
            setUser={setUser}
            setPaymentData={setPaymentData}
            query={query}
          />
        }
      />
      <Route path="terms" element={<InfoText user={user} content={terms} />} />
      <Route
        path="privacy"
        element={<InfoText user={user} content={privacy} />}
      />
      <Route path="opt-out" element={<OptOut user={user} />} />
      <Route index path="/" element={<Landing user={user}/>} />
    </Routes>
  );
}

export default App;
