import React from "react";
import style from "./title.module.css";

type Props = {
  type: string;
  children: string | JSX.Element;
};

const Title = ({ type,
  children }: Props): JSX.Element | null => {
  if (type === "h1") {
    return <h1 className={style.h1}>{children}</h1>;
  }
  if (type === "h2") {
    return <h2 className={style.h2}>{children}</h2>;
  }
  if (type === "h3") {
    return <h3 className={style.h3}>{children}</h3>;
  }
  if (type === "h4") {
    return <h4 className={style.h4}>{children}</h4>;
  }
  return null;
};

export default Title;
