import { Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";

import style from "./thankyou.module.css";

const Step4 = (props: {
  user: {
    islogged: boolean;
    email: string;
    token: string;
  };
  setUser: Function;
  setPaymentData: Function;
}): JSX.Element => {
  let navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("pu_user") !== null) {
      let localUserData = JSON.parse(localStorage.getItem("pu_user") || "{}");
      if (new Date().valueOf() - localUserData.time < 600000) {
        props.setUser({
          islogged: localUserData.islogged,
          email: localUserData.email,
          token: localUserData.token,
        });
        localStorage.setItem(
          "pu_user",
          JSON.stringify({
            islogged: true,
            email: localUserData.email,
            token: localUserData.token,
            time: new Date().valueOf(),
          })
        );
        let localPaymentData = JSON.parse(
          localStorage.getItem("pu_payment") || "{}"
        );
        props.setPaymentData({
          credits: localPaymentData.credits,
          stripeCustomerId: localPaymentData.stripeCustomerId,
        });
      } else {
        if (!props.user.islogged) {
          navigate("/login");
        }
      }
    } else {
      if (!props.user.islogged) {
        navigate("/login");
      }
    }
  }, []);
  const handleNextClick = () => {
    navigate("/dashboard");
  };

  return (
    <div className="pageWrapper">
      <main className="mainWrapper">
        <Header user={props.user} />
        <Box className={style.mainBox}>
          <Box className={style.headingBox}>
            <Title type="h2">THANK YOU</Title>
          </Box>
          <Box className={style.paymentText}>
            |Your data is beind prepared.<br></br>
          </Box>
          <Box className={style.emailText}>
            We’ll send you an 📧 email when your export is ready to{" "}
            <strong>export & download</strong> 🫡
          </Box>
          <Box className={style.dashboardText}>
            Or check <span onClick={handleNextClick}>your dashboard</span>
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Step4;
