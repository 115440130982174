import { Box, Button, Chip, SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";
import style from "./home.module.css";

const Home = (props: {
  user: {
    islogged: boolean;
    email: string;
    token: string;
  };
}): JSX.Element => {
  const [category, setCategory] = useState("");
  let navigate = useNavigate();
  const peopleCategories = [
    "influencer",

    "blogger",

    "journalist",

    "musician",

    "photographer",

    "designer",
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleGetLeadsClick = (event: SelectChangeEvent) => {
    console.log("s");
  };

  return (
    <div className="pageWrapper">
      <main className="mainWrapper">
        <Header user={props.user} />
        <Box sx={{ marginBottom: "40px" }}>
          <Title type="h1">Find people you want to connect with.</Title>
        </Box>
        <Box
          className={style.tagBox}
          sx={{
            widht: "100%",

            display: "flex",

            justifyContent: "space-around",

            flexWrap: "wrap",
          }}
        >
          {peopleCategories.map((cat, index) => (
            <Chip
              key={`${cat}-tag-${index}`}
              className={style.MUIchip}
              sx={(theme) => ({
                margin: "0",

                [theme.breakpoints.down("md")]: {
                  margin: "0 3px 15px 0",
                },
              })}
              label={cat}
              variant="outlined"
            />
          ))}
        </Box>
        <Box className={style.signUpBox} sx={{ marginBottom: "40px" }}>
          Sign up for free.
        </Box>
        <Box className={style.getTextBox} sx={{ marginBottom: "40px" }}>
          Get 50 FREE opportunities on the house.
        </Box>
        <Button
          onClick={() => {
            navigate("/sign-up");
          }}
          variant="contained"
          className={style.getLeadsBtn}
        >
          GET 50 LEADS FOR FREE
        </Button>
        <Box className={style.riskTextBox} sx={{ marginBottom: "60px" }}>
          try it risk free. No CC required.
        </Box>
        <Box sx={{ marginBottom: "60px" }}>
          <nav className={style.nav}>
            <ul>
              <li>
                <Link to="/sign-up">sign up</Link>
              </li>
              <li>
                <Link to="/login">log in</Link>
              </li>
              <li>
                <Link to="/opt-out">opt out</Link>
              </li>
              <li>
                <Link to="https://docs.google.com/document/d/1J0RYNHuYR5_X2FyypQYnabZMaCzq5A_T5ip4v5hWGjE/edit?usp=sharing">
                  terms
                </Link>
              </li>
              <li>
                <Link to="https://docs.google.com/document/d/14V0XAdJGD2ae2q7BcByVH6sGz4FEeHlM2bBV5BfJfYo/edit?usp=sharing">
                  privacy & legal
                </Link>
              </li>
            </ul>
          </nav>
        </Box>
      </main>
    </div>
  );
};

export default Home;

{
  /* <div className={style.connectHeader}>
connect <span>with</span>
</div>
<Box>
<form className={style.formBox}>
  <FormControl
    fullWidth
    sx={{
      margin: "0 0 35px 0",

      "@media (min-width: 991px)": {
        margin: "0 35px 0 0",

      },

    }}
  >
    <InputLabel
      sx={{
        fontStyle: "normal",

        fontWeight: "700",

        fontSize: "22px",

        transform: "translate(18px,
 22px) scale(1)",

        lineHeight: "30px",

        color: "#333333",

        "&.MuiInputLabel-shrink": {
          transform: "translate(20px,
 -9px) scale(0.75)",

        },

      }}
      id="emails-category"
    >
      Category
    </InputLabel>
    <Select
      sx={{
        borderRadius: "30px",

        padding: "0 5px",

        fontStyle: "normal",

        fontWeight: "700",

        fontSize: "28px",

        lineHeight: "44px",

        color: "#333333",

      }}
      className={style.select}
      labelId="emails-category"
      id="emails-category-select"
      value={category}
      label="Category"
      onChange={handleChange}
    >
      {peopleCaterogies.map((cat,
 index) => (
        <MenuItem key={index} value={cat}>
          {cat}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  <Button
    onClick={() => {}}
    type="submit"
    variant="contained"
    sx={{
      borderRadius: "30px",

      backgroundColor: "rgba(135,
  83,
  244,
 0.75)",

      fontStyle: "normal",

      fontWeight: "700",

      fontSize: "28px",

      lineHeight: "34px",

      padding: "24px 0",

      width: "100%",

      color: "#fcfcfc",

      "&:hover": {
        backgroundColor: "rgba(135,
  83,
  244,
 1)",

      },

    }}
  >
    Contained
  </Button>
</form>
</Box>
<Box className={style.textResult}>100k plus results</Box> */
}
