import { Box, Link as LinkComp } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";
import style from "./dashboard.module.css";
type Filter = {
  name: string;
  word: string;
  words: string[];
  min: number;
  max: number;
};
type SearchQuery = {
  available: number;
  csvLink: string;
  limit: number;
  isPaid: boolean;
  isCredit: boolean;
  filters: Filter[];
};
const Dashboard = (props: {
  user: { islogged: boolean; email: string; token: string };
  setUser: Function;
  setPaymentData: Function;
}): JSX.Element => {
  let navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("pu_user") !== null) {
      let localUserData = JSON.parse(localStorage.getItem("pu_user") || "{}");
      if (new Date().valueOf() - localUserData.time < 600000) {
        props.setUser({
          islogged: localUserData.islogged,
          email: localUserData.email,
          token: localUserData.token,
        });
        localStorage.setItem(
          "pu_user",
          JSON.stringify({
            islogged: true,
            email: localUserData.email,
            token: localUserData.token,
            time: new Date().valueOf(),
          })
        );
        let localPaymentData = JSON.parse(
          localStorage.getItem("pu_payment") || "{}"
        );
        props.setPaymentData({
          credits: localPaymentData.credits,
          stripeCustomerId: localPaymentData.stripeCustomerId,
        });
      } else {
        if (!props.user.islogged) {
          navigate("/login");
        }
      }
    } else {
      if (!props.user.islogged) {
        navigate("/login");
      }
    }
  }, []);
  const [queryData, setQueryData] = useState<SearchQuery[]>([]);
  useEffect(() => {
    if (!props.user.islogged) {
      navigate("/login");
    } else {
      fetch("https://people-backend.upgiant.com/query/", {
        headers: { Authorization: props.user.token },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.message == undefined) {
            setQueryData(
              result.filter(function (item: SearchQuery) {
                return item.isPaid;
              })
            );
          }
          console.log(result);
        });
    }
  }, []);

  return (
    <div className="pageWrapper">
      <main className="mainWrapper">
        <Header user={props.user} />
        <Box className={style.mainBox}>
          <Box className={style.headingBox}>
            <Title type="h2">DASHBOARD</Title>
          </Box>
          <Box className={style.newButtonBox}>
            <Link to="/search">New Search</Link>
          </Box>
          <Box className={style.descrBox}>
            Download links are only available for 30 days
          </Box>
          {queryData &&
            queryData.map((item: SearchQuery, i) => {
              return (
                <Box className={style.dashTextBox} key={i}>
                  <p>
                    <strong>Search #{i + 1}</strong>
                  </p>
                  <p>
                    <strong>{item.available} contacts</strong>
                  </p>
                  <p>{item.isCredit ? "Free Trial" : "Paid Search"}</p>
                  {item.filters.map((filter: Filter, j) => {
                    let content: string = "";
                    if (filter.name == "category" && filter.word != "") {
                      content = filter.word;
                    } else if (
                      filter.name == "category" &&
                      filter.words.length > 0
                    ) {
                      content = filter.words.join(", ");
                    } else if (
                      filter.name == "Category" &&
                      filter.words.length > 0
                    ) {
                      content = filter.words.join(", ");
                    } else if (filter.name == "keyword") {
                      content = filter.words.join(", ");
                    } else if (filter.name == "followers") {
                      content =
                        filter.min +
                        " - " +
                        (filter.max === undefined ? "max" : String(filter.max));
                    }
                    return (
                      <p key={j}>
                        <b>{filter.name}:</b> {content}
                      </p>
                    );
                  })}
                  <LinkComp
                    className={style.link}
                    target="_blank"
                    href={item.csvLink}
                  >
                    Download
                  </LinkComp>
                </Box>
              );
            })}
        </Box>
      </main>
    </div>
  );
};

export default Dashboard;
