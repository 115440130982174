import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/CheckoutForm";
import { LOGIN_URL, PAYMENT_URL, STRIPE_ID } from "../../constants";
import axios from "axios";
import { useEffect, useState } from "react";
import { isPropertySignature } from "typescript";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";
import style from "./payment.module.css";

type StepComponentProps = {
  setStep: (step: number) => void;
};

const stripePromise = loadStripe(STRIPE_ID);

const PaymentPage = (props: {
  user: {
    islogged: boolean;
    email: string;
    token: string;
  };
  query: { id: string };
  setUser: Function;
  setPaymentData: Function;
}): JSX.Element => {
  let navigate = useNavigate();

  const [clientSecret, setSecret] = useState("");
  const [savedPayments, setSavedPayments] = useState([]);
  const [selectedPaymentId, setSelectedPaymentMethodId] = useState("new");

  useEffect(() => {
    if (localStorage.getItem("pu_user") !== null) {
      let localUserData = JSON.parse(localStorage.getItem("pu_user") || "{}");
      if (new Date().valueOf() - localUserData.time < 600000) {
        props.setUser({
          islogged: localUserData.islogged,
          email: localUserData.email,
          token: localUserData.token,
        });
        localStorage.setItem(
          "pu_user",
          JSON.stringify({
            islogged: true,
            email: localUserData.email,
            token: localUserData.token,
            time: new Date().valueOf(),
          })
        );
        let localPaymentData = JSON.parse(
          localStorage.getItem("pu_payment") || "{}"
        );
        props.setPaymentData({
          credits: localPaymentData.credits,
          stripeCustomerId: localPaymentData.stripeCustomerId,
        });
      } else {
        if (!props.user.islogged) {
          navigate("/login");
        }
      }
    } else {
      if (!props.user.islogged) {
        navigate("/login");
      }
    }
  }, []);

  useEffect(() => {
    fetchSavedPayment();
  }, []);

  useEffect(() => {
    if (selectedPaymentId === "new") {
      (async () => {
        const secret = await fetchPaymentSecretForNewCard();
        setSecret(secret);
      })();
    }
  }, [selectedPaymentId]);

  const fetchSavedPayment = async () => {
    //TODO we don't need to do this. We will save token on local storage and read from there
    /*const { data } = await axios.post(LOGIN_URL,
 {
            "email": "sanjivg@gmail.com",

            "password": "test"
        });*/
    const response = await axios.post(
      PAYMENT_URL + "get-customer-saved-payments",
      {},
      {
        headers: {
          Authorization: props.user.token,
        },
      }
    );

    setSavedPayments(response.data.paymentMethods);
  };

  const fetchPaymentSecretForNewCard = async (selectedPaymentId = "new") => {
    // TODO same as above for this.
    /*const { data } = await axios.post(LOGIN_URL,
 {
            "email": "sanjivg@gmail.com",

            "password": "test"
        });*/

    // TODO the query Id won't be hard coded. Since, we first check the availability of their query and only then ask them for their
    // credit cards, we will have queryId from /query/create endpoint. pass that query id here
    const response = await axios.post(
      PAYMENT_URL + "get-payment-secret",
      {
        queryId: props.query.id,

        selectedPaymentId,
      },
      {
        headers: {
          Authorization: props.user.token,
        },
      }
    );
    return response.data.paymentSecret as string;
  };

  const options = {
    clientSecret,
  };

  return clientSecret ? (
    <div className="pageWrapper">
      <main className="mainWrapper">
        <Header user={props.user} link={false} />
        <Box className={style.mainHeadingBox}>
          <Title type="h2">Choose Payment Method</Title>
        </Box>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            fetchPaymentSecretForNewCard={fetchPaymentSecretForNewCard}
            clientSecret={clientSecret}
            selectedPaymentMethodId={selectedPaymentId}
            setSelectedPaymentMethodId={setSelectedPaymentMethodId}
            paymentMethods={savedPayments}
          />
        </Elements>
      </main>
    </div>
  ) : (
    <>Loading ...</>
  );
};

export default PaymentPage;
