import style from "./landing.module.css";
import { Box, Link as LinkComp } from "@mui/material";
import landing_image from "./../../assets/footer-image.png";
import { Link } from "react-router-dom";
import { useState } from "react";

const Landing = (props: {
  user: {
    islogged: boolean;
    email: string;
    token: string;
  };
}): JSX.Element => {
  const [convNumber,setConvNumber]=useState(0);
  const [convPercentage,setConvPercentage]=useState(0);
  const [queriesNumber,setQueriesNumber]=useState(0);
  const handleChangeHash = (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(e.target.value);
    setConvNumber(Number(e.target.value));
    if(convNumber>0 && convPercentage>0){
      setQueriesNumber(Math.round(Number(e.target.value)*100/convPercentage));
    }
  };
  const handleChangePrecent = (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(e.target.value);
    setConvPercentage(Number(e.target.value));
    if(convNumber>0 && convPercentage>0){
      setQueriesNumber(Math.round(convNumber/(Number(e.target.value)/100)));
    }
  };

  return (
    <>
      <main className={style.landingMain}>
        <section className={`${style.sectionCommon} ${style.firstSection}`}>
          <Box className={style.contentWrapper}>
            <Box className={style.logoText}>sendmatter</Box>
            <h1 className={style.sectionText}>
              You can do it.
              <strong>Achieve outreach goals with more opportunities</strong>
            </h1>
            <LinkComp className={style.firstSectionLink} href={"/sign-up"}>
              Get 50 opportunities for free
            </LinkComp>
          </Box>
        </section>
        <section className={`${style.sectionCommon} ${style.secondSection}`}>
          <Box className={`${style.contentWrapper} ${style.smallWrapper}`}>
            <h2 className={style.secondHeading}>
              Wait, outreach is a numbers game?
              <br></br>
              <br></br>
              <span>Always has been.</span>
            </h2>
            <Box className={style.knowNumbers}>
              <div className={style.knowNumbersHeading}>Know your numbers</div>
              <p>
                <strong>
                  How many opportunities do you need to contact to reach your
                  goal?
                </strong>
              </p>
              <p className={style.noMargin}>
                Goal # of conversions?{" "}
                <span>
                  [
                  <input
                    onChange={handleChangeHash}
                    className={style.inputText}
                    placeholder="#"
                    type="number"
                  />
                  ]
                </span>
              </p>
              <p>
                Estimated conversion rate{" "}
                <span>
                  [
                  <input
                    onChange={handleChangePrecent}
                    className={style.inputText}
                    placeholder="%"
                    type="number"
                  />
                  ]
                </span>
                %
              </p>
              <p>
                You need <strong>{queriesNumber==0||!isFinite(queriesNumber)?"X":String(queriesNumber)}</strong> outreach opportunities to reach your
                goal
              </p>
            </Box>
          </Box>
        </section>
        <section className={`${style.sectionCommon} ${style.thirdSection}`}>
          <Box className={`${style.contentWrapper} ${style.smallWrapper}`}>
            <h2 className={style.thirdHeading}>
              <span>Try it.</span> Get 50 FREE opportunity credits
            </h2>
            <LinkComp className={style.thirdSectionLInk} href={"/sign-up"}>
              Get 50 opportunities for free
            </LinkComp>
            <Box className={style.imageBox}>
              <img src={landing_image} alt="People" />
            </Box>
            <Box>
              <nav className={style.nav}>
                <ul>
                  <li>
                    <Link to="/sign-up">sign up</Link>
                  </li>
                  <li>
                    <Link to="/login">log in</Link>
                  </li>

                  <li>
                    <Link to="https://docs.google.com/document/d/1J0RYNHuYR5_X2FyypQYnabZMaCzq5A_T5ip4v5hWGjE/edit?usp=sharing">
                      terms
                    </Link>
                  </li>
                  <li>
                    <Link to="https://docs.google.com/document/d/14V0XAdJGD2ae2q7BcByVH6sGz4FEeHlM2bBV5BfJfYo/edit?usp=sharing">
                      privacy
                    </Link>
                  </li>
                </ul>
              </nav>
            </Box>
          </Box>
        </section>
      </main>
    </>
  );
};

export default Landing;
