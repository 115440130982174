import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useState } from "react";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";
import style from "./optout.module.css";

type stepDataType = {
  name: string;
  email: string;
  link: string;
  reason: string;
};

const initialStepData = {
  name: "",
  email: "",
  link: "",
  reason: "",
};

const OptOut = (props: {
  user: {
    islogged: boolean;
    email: string;
    token: string;
  };
}): JSX.Element => {
  const [optOutData, setOptOutData] = useState<stepDataType>(initialStepData);

  const handleChange = (
    event:
      | SelectChangeEvent
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setOptOutData({
      ...optOutData,
      [event.target.name]: event.target.value,
    });
  };

  const reasons = ["reason1", "reason2", "reason3", "reason4"];

  return (
    <div className="pageWrapper">
      <main className="mainWrapper">
        <Header user={props.user} />
        <Box className={style.mainHeadingBox}>
          <Title type="h1">Please fill in the form.</Title>
        </Box>
        <Box className={style.formBox}>
          <form>
            <Box className={style.inputBox}>
              <TextField
                onChange={handleChange}
                className={style.inputText}
                placeholder="Name"
                type="text"
                name="name"
                variant="outlined"
              />
            </Box>
            <Box className={style.inputBox}>
              <TextField
                onChange={handleChange}
                className={style.inputText}
                placeholder="Email"
                type="email"
                name="email"
                variant="outlined"
              />
            </Box>
            <Box className={style.inputBox}>
              <TextField
                onChange={handleChange}
                className={style.inputText}
                placeholder="Link to IG page to exlude from search"
                type="text"
                name="link"
                variant="outlined"
              />
            </Box>
            <Box className={style.inputBox}>
              <Select
                name="country"
                inputProps={{ "aria-label": "Without label" }}
                className={style.inputSelect}
                value={optOutData.reason}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Reason
                </MenuItem>
                {reasons.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                className={style.button}
              >
                Send request
              </Button>
            </Box>
          </form>
        </Box>
        <Box className={style.afterFormBox}>
          We'll notify you by email as soon as your page will be removed from
          search
        </Box>
      </main>
    </div>
  );
};

export default OptOut;
