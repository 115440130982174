import React from "react";
import style from "./header.module.css";
import logo from "../../assets/logo-u.png";
import burger from "../../assets/burger.png";
import { Link } from "react-router-dom";

// type HeaderProps = {
//   link?: boolean;
// };

// { link = true }: HeaderProps

const Header = (props: {
  user: {
    islogged: boolean;
    email: string;
    token: string;
  };
  link?: boolean;
}): JSX.Element => {
  let hamburgerLink = props.user.islogged ? "/dashboard" : "/login";
  let link = props.link === false ? false : true;
  return (
    <>
      <header className={style.header}>
        <img src={logo} alt="Upgiant logo" />
        {link && (
          <Link to={hamburgerLink}>
            <img src={burger} alt="burger" />
          </Link>
        )}
      </header>
    </>
  );
};

export default Header;
