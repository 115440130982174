import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";
import style from "./login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { send } from "process";

type stepDataType = {
  email: string;
  password: string;
};

const initialStepData = {
  email: "",
  password: "",
};

const Login = (props: {
  setPaymentData: Function;
  user: {
    islogged: boolean;
    email: string;
    token: string;
  };
  setUser: Function;
}): JSX.Element => {
  let navigate = useNavigate();
  useEffect(() => {
    if (props.user.islogged) {
      navigate("/dashboard");
    }
  }, []);
  const [loginData, setLoginData] = useState<stepDataType>(initialStepData);
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLoginData({
      ...loginData,

      [event.target.name]: event.target.value,
    });
  };
  const sendLoginData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetch("https://people-backend.upgiant.com/auth/login", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: loginData.email,
        password: loginData.password,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.message == undefined) {
          props.setUser({
            islogged: true,
            email: loginData.email,
            token: result.token,
          });
          localStorage.setItem(
            "pu_user",
            JSON.stringify({
              islogged: true,
              email: loginData.email,
              token: result.token,
              time: new Date().valueOf(),
            })
          );
          fetch("https://people-backend.upgiant.com/auth/profile", {
            headers: { Authorization: result.token },
          })
            .then((res) => res.json())
            .then((result) => {
              props.setPaymentData({
                credits: result.credits,
                stripeCustomerId: result.stripeCustomerId,
              });
              localStorage.setItem(
                "pu_payment",
                JSON.stringify({
                  credits: result.credits,
                  stripeCustomerId: result.stripeCustomerId,
                })
              );
              navigate("/dashboard");
            });
        } else {
          setErrorMessage(result.message);
        }
      });
  };
  return (
    <div className="pageWrapper">
      <main className="mainWrapper">
        <Header user={props.user} link={false} />
        <Box className={style.mainHeadingBox}>
          <Title type="h1">Login into your account</Title>
        </Box>
        <Box className={style.formBox}>
          <form onSubmit={sendLoginData}>
            <Box className={style.inputBox}>
              <TextField
                onChange={handleChange}
                className={style.inputText}
                placeholder="Email"
                type="email"
                name="email"
                variant="outlined"
              />
            </Box>
            <Box className={style.inputBox}>
              <TextField
                onChange={handleChange}
                className={style.inputText}
                placeholder="Password"
                type="password"
                name="password"
                variant="outlined"
              />
            </Box>

            <Box>
              <Button
                type="submit"
                variant="contained"
                className={style.button}
              >
                Login
              </Button>
            </Box>
          </form>
        </Box>
        <Box className={style.errorFormBox}>{errorMessage}</Box>
        <Box className={style.afterFormBox}>
          New user? <Link to="/sign-up">Sign up</Link>
        </Box>
      </main>
    </div>
  );
};

export default Login;
