import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";
import style from "./search.module.css";
import geoData from "countries-list";
import { useNavigate } from "react-router-dom";
import optionsData from "../../constants/categories.json";
import LoadingOverlay from "react-loading-overlay-ts";

type stepDataType = {
  isSearched: boolean;

  keywordSearch: string;
  keywordState: string;
  categoriesState: string;
  country: string;
  audience: string;
  audienceMin: number;
  audienceMax: number;
  maxResults: number;
};

const initialStepData = {
  isSearched: false,
  keywordSearch: "",
  keywordState: "",
  categoriesState: "",
  country: "",
  audience: "",
  audienceMin: 0,
  audienceMax: 0,
  maxResults: 100000,
};

const Search = (props: {
  paymentData: { credits: number; stripeCustomerId: string };
  setPaymentData: Function;
  user: { islogged: boolean; email: string; token: string };
  searchParams: stepDataType;
  setSearchParams: Function;
  setQuery: Function;
  setUser: Function;
}): JSX.Element => {
  let navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("pu_user") !== null) {
      let localUserData = JSON.parse(localStorage.getItem("pu_user") || "{}");
      if (new Date().valueOf() - localUserData.time < 600000) {
        props.setUser({
          islogged: localUserData.islogged,
          email: localUserData.email,
          token: localUserData.token,
        });
        localStorage.setItem(
          "pu_user",
          JSON.stringify({
            islogged: true,
            email: localUserData.email,
            token: localUserData.token,
            time: new Date().valueOf(),
          })
        );
        let localPaymentData = JSON.parse(
          localStorage.getItem("pu_payment") || "{}"
        );
        props.setPaymentData({
          credits: localPaymentData.credits,
          stripeCustomerId: localPaymentData.stripeCustomerId,
        });
      } else {
        if (!props.user.islogged) {
          navigate("/login");
        }
      }
    } else {
      if (!props.user.islogged) {
        navigate("/login");
      }
    }
  }, []);

  const [stepData, setStepData] = useState<stepDataType>(initialStepData);
  const countriesArr = Object.values(geoData.countries);
  const [resultsCount, setResultsCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const categoriesOptions = optionsData;
  const [errorMessage, setErrorMessage] = useState("");
  const handleSelectChange = (event: any) => {
    setStepData({
      ...stepData,

      [event.target.name]: event.target.value,
    });
    //console.log(stepData);
  };
  const performSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let requestBody: any = {};
    setLoading(true);
    requestBody.filters = [];
    requestBody.limit = 100000;
    if (props.user.islogged) {
      requestBody.email = props.user.email;
    }
    if (stepData.maxResults != 0) {
      requestBody.limit = stepData.maxResults;
    }
    if (stepData.keywordSearch != "") {
      requestBody.filters.push({
        name: "keyword",
        words: stepData.keywordSearch.split(" "),
      });
    }
    /*if(stepData.country!=""){
      requestBody.filters.push({
        "name": "country",

        "word": stepData.country
      })
    }*/
    if (stepData.categoriesState != "") {
      requestBody.filters.push({
        name: "category",
        word: stepData.categoriesState,
      });
    }
    if (stepData.audienceMax != 0) {
      requestBody.filters.push({
        name: "followers",
        min: stepData.audienceMin,
        max: stepData.audienceMax,
      });
    } else {
      requestBody.filters.push({
        name: "followers",
        min: stepData.audienceMin,
      });
    }
    fetch("https://people-backend.upgiant.com/query/search", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: props.user.token,
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.message == undefined) {
          if (result.count > 0) {
            setResultsCount(result.count);
            setStepData({
              ...stepData,
              isSearched: true,
            });
            props.setSearchParams({
              ...stepData,
              isSearched: true,
            });
          }
        } else {
          setErrorMessage(result.message);
        }
        setLoading(false);
      });
  };
  const sendFreeQuery = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    let requestBody: any = {};
    requestBody.filters = [];
    requestBody.limit = Math.min(
      props.paymentData.credits,
      resultsCount,
      stepData.maxResults
    );
    //console.log(requestBody.limit);
    requestBody.useCredit = true;
    if (props.user.islogged) {
      requestBody.email = props.user.email;
    }
    if (stepData.keywordSearch != "") {
      requestBody.filters.push({
        name: "keyword",
        words: stepData.keywordSearch.split(" "),
      });
    }
    /*if(stepData.country!=""){
      requestBody.filters.push({
        "name": "country",
        "word": stepData.country
      })
    }*/
    if (stepData.categoriesState != "") {
      requestBody.filters.push({
        name: "category",

        word: stepData.categoriesState,
      });
    }
    if (stepData.audienceMax != 0) {
      requestBody.filters.push({
        name: "followers",

        min: stepData.audienceMin,

        max: stepData.audienceMax,
      });
    } else {
      requestBody.filters.push({
        name: "followers",

        min: stepData.audienceMin,
      });
    }
    fetch("https://people-backend.upgiant.com/query/create", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: props.user.token,
      },

      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);  
        localStorage.setItem(
          "pu_payment",
          JSON.stringify({
            credits: props.paymentData.credits - requestBody.limit,
            stripeCustomerId: props.paymentData.stripeCustomerId,
          })
        );

        props.setPaymentData({
          ...props.paymentData,
          credits: props.paymentData.credits - requestBody.limit,
        });
        navigate("/thank-you");
      });
  };
  const sendPaidQuery = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    let requestBody: any = {};
    requestBody.filters = [];
    requestBody.limit = 100000;
    if (props.user.islogged) {
      requestBody.email = props.user.email;
    }
    if (stepData.maxResults != 0) {
      requestBody.limit = stepData.maxResults;
    }
    if (stepData.keywordSearch != "") {
      requestBody.filters.push({
        name: "keyword",

        words: stepData.keywordSearch.split(" "),
      });
    }
    /*if(stepData.country!=""){
      requestBody.filters.push({
        "name": "country",

        "word": stepData.country
      })
    }*/
    if (stepData.categoriesState != "") {
      requestBody.filters.push({
        name: "category",

        word: stepData.categoriesState,
      });
    }
    if (stepData.audienceMax != 0) {
      requestBody.filters.push({
        name: "followers",
        min: stepData.audienceMin,
        max: stepData.audienceMax,
      });
    } else {
      requestBody.filters.push({
        name: "followers",
        min: stepData.audienceMin,
      });
    }
    fetch("https://people-backend.upgiant.com/query/create", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: props.user.token,
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        if (result.message == undefined) {
          if (result.available > 0) {
            props.setQuery({ id: result.queryId });
            navigate("/payment");
          }
        } else {
          setErrorMessage(result.message);
        }
      });
  };
  return (
    <LoadingOverlay active={isLoading} spinner text="Processing your query...">
    <div className="pageWrapper">
       
      <main className="mainWrapper">
        <Header user={props.user} />
        <Box className={style.mainHeadingBox}>
          <Title type="h1">Find & export outreach opportunities.</Title>
        </Box>
        <Box
          style={stepData.isSearched ? {} : { display: "none" }}
          className={style.resutlText}
        >
          {resultsCount} results
        </Box>
        <Box className={style.subHeadingBox}>
          <Title type="h3">FIND YOUR PEOPLE</Title>
        </Box>
        <Box className={style.formBox}>
          <form onSubmit={performSearch}>
            <Box className={style.inputHeadingBox}>
              <Title type="h4">Keywords</Title>
            </Box>
            <Box className={style.inputBox}>
              <TextField
                onChange={handleSelectChange}
                className={style.inputText}
                placeholder="Keywords"
                type="text"
                name="keywordSearch"
                variant="outlined"
              />
            </Box>
            {/*<Box className={style.inputHeadingBox}>
            <Title type="h4">Country</Title>
          </Box>
          <Box className={style.inputBox}>
            <Select
              name="country"
              inputProps={{ "aria-label": "Without label" }}
              className={style.inputSelect}
              value={stepData.country}
              onChange={handleSelectChange}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Country
              </MenuItem>
              {countriesArr.map((country) => (
                <MenuItem
                  key={country.name.toLowerCase()}
                  value={country.name}
                >
                  {country.name}
                </MenuItem>
              ))}
            </Select>
              </Box>*/}
            <Box className={style.inputHeadingBox}>
              <Title type="h4">Categories</Title>
            </Box>
            <Box className={style.inputBox}>
              <Autocomplete
                disablePortal
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
                className={style.inputSelect}
                options={categoriesOptions}
                value={stepData.categoriesState}
                onChange={(e, v) => {
                  setStepData({
                    ...stepData,
                    categoriesState: v || "",
                  });
                }}
              />
            </Box>
            <Box className={style.inputHeadingBox}>
              <Title type="h4">Followers count</Title>
            </Box>
            <Box className={style.inputBox}>
              <TextField
                className={style.inputNubmer}
                placeholder="MIN"
                inputProps={{ min: 10, step: 1 }}
                type="number"
                name="audienceMin"
                variant="outlined"
                onChange={handleSelectChange}
              />
              <TextField
                className={style.inputNubmer}
                placeholder="MAX"
                type="number"
                name="audienceMax"
                variant="outlined"
                onChange={handleSelectChange}
              />
            </Box>
            <Box className={style.inputHeadingBox}>
              <Title type="h4">Maximum number of results</Title>
            </Box>
            <Box className={style.inputBox}>
              <TextField
                className={style.inputNubmerFull}
                placeholder="MAX Number of results"
                type="number"
                name="maxResults"
                variant="outlined"
                onChange={handleSelectChange}
              />
            </Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                className={style.button}
              >
                Search
              </Button>
            </Box>
          </form>
        </Box>
        <Box className={style.errorFormBox}>{errorMessage}</Box>
        {
          <Box
            style={
              stepData.isSearched && props.paymentData.credits > 0
                ? {}
                : { display: "none" }
            }
          >
            <Button
              type="submit"
              variant="contained"
              onClick={sendFreeQuery}
              className={style.buttonSendTrial}
            >
              Free trial offer Download {props.paymentData.credits}/
              {resultsCount} for $0
            </Button>
          </Box>
        }
        {
          <Box
            style={
              stepData.isSearched && props.user.islogged
                ? {}
                : { display: "none" }
            }
          >
            <Button
              type="submit"
              variant="contained"
              onClick={sendPaidQuery}
              className={style.buttonSend}
            >
              Get all {resultsCount} records for $
              {(resultsCount * 0.05).toFixed(2)}
            </Button>
          </Box>
        }
      </main>
      
    </div>
    </LoadingOverlay>
  );
};

export default Search;
