import { Button } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import SavedPaymentMethods from "../SavedPayment";

import style from "./checkout.module.css";

interface ISavedPaymentMethods {
  paymentMethods: any[];
  selectedPaymentMethodId: string;
  setSelectedPaymentMethodId: Dispatch<SetStateAction<string>>;
  clientSecret: string;
  fetchPaymentSecretForNewCard: (id: string) => Promise<string>;
}

const CheckoutForm = ({
  paymentMethods,
  selectedPaymentMethodId,
  setSelectedPaymentMethodId,
  clientSecret,
  fetchPaymentSecretForNewCard,
}: ISavedPaymentMethods) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // TODO use this to render the button and disable the form and button accordingly
  const [loading, setLoading] = useState(false);

  console.log(paymentMethods);
  let navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    // We don't want to let default form submission happen here,

    // which would refresh the page.

    // TODO set loading here. Add a try catch block. setLoading false on catch block ...

    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (selectedPaymentMethodId !== "new") {
      const cardPayment = paymentMethods.find(
        (method) => method.id === selectedPaymentMethodId
      );

      if (cardPayment) {
        const clientSecret = await fetchPaymentSecretForNewCard(cardPayment.id);
        const { error, paymentIntent } = await stripe.retrievePaymentIntent(
          clientSecret
        );

        if (error) {
          console.log("Error retrieving payment intent:", error);
        } else if (paymentIntent.status === "succeeded") {
          console.log("Payment Intent has already succeeded");
          navigate("/thank-you-page");
        }
      }
      return;
    } else {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // TODO : change this to thank you page
          return_url: "https://people.upgiant.com/thank-you-page",
        },
      });
      if (error) {
        setErrorMessage(error.message || "");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          padding: 10,
          border: "1px solid black",
          borderRadius: 30,
          marginTop: 10,
          marginBottom: 30,
        }}
        key={"new"}
      >
        <input
          type="radio"
          name="paymentMethod"
          value={"new"}
          checked={selectedPaymentMethodId === "new"}
          onChange={(event) => setSelectedPaymentMethodId("new")}
        />
        <span style={{ marginLeft: 10 }}>New Card</span>
      </div>
      {selectedPaymentMethodId === "new" && <PaymentElement />}
      <SavedPaymentMethods
        selectedPaymentMethodId={selectedPaymentMethodId}
        setSelectedPaymentMethodId={setSelectedPaymentMethodId}
        paymentMethods={paymentMethods}
      />
      {/* {selectedPaymentMethodId === "new" && */}
      <Button
        type="submit"
        disabled={!stripe}
        variant="contained"
        className={style.button}
      >
        Submit
      </Button>

      {/* TODO style this and use RED COLOR */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;
