import { Box } from "@mui/material";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";
import style from "./infotext.module.css";

type ContentType = {
  title: string;
  text: string;
};

const InfoText = (props: {
  user: {
    islogged: boolean;
    email: string;
    token: string;
  };
  content: ContentType;
}): JSX.Element => {
  return (
    <div className="pageWrapper">
      <main className="mainWrapper">
        <Header user={props.user} />
        <Box className={style.mainHeadingBox}>
          <Title type="h1">{props.content.title}</Title>
        </Box>
        <Box className={style.text}>{props.content.text}</Box>
      </main>
    </div>
  );
};

export default InfoText;
